const LD_PROMOTIONS_GENERAL_USERS = "promotionsGeneralUsers";
const LD_PROMOTIONS_SUPER_USERS = "promotionsSuperUsers";

export const PROMOTIONS_LAUNCH_DARKLY_FLAGS = {
  /*
  PLEASE NAME FLAGS STARTING WITH THE RELATED ENTITY - AND KEEP ALL FLAGS RELATED TO THE SAME ENTITY TOGETHER
   */
  promotionSyndicationEdit: LD_PROMOTIONS_SUPER_USERS,
  promotionSyndicationView: LD_PROMOTIONS_GENERAL_USERS,
};
