import styles from "./ProfileDropdown.module.css";
import React, { FunctionComponent } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Avatar } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { useSession, signOut, signIn } from "next-auth/react";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocalStorage } from "usehooks-ts";
import Router from "next/router";
import { useUserInfo } from "@/api/graphql";

const ProfileContent: FunctionComponent<{
  anchor?: HTMLElement;
  show: boolean;
  user: {
    name?: string | null;
    email?: string | null;
    image?: string | null;
  };
  avatarUrl: string;
  title: string;
}> = ({ user, anchor, show, avatarUrl, title }) => {
  const { tmiDarkMode } = useFlags() || {};
  const [isDarkTheme, setDarkTheme] = useLocalStorage("darkTheme", false);

  return user ? (
    <Popup className={styles["user-popup"]} show={show} anchor={anchor}>
      {/* https://thrasio.atlassian.net/browse/RD-983 */}
      {/* eslint-disable no-inline-styles/no-inline-styles */}
      <div
        style={{
          padding: "5px 0px",
          right: 35,
          textAlign: "center",
        }}
      >
        <div className={styles["user-popup-avatar-container"]}>
          <Avatar size="large" type="image">
            <Image src={avatarUrl} height={100} width={100} alt={user.name} />
          </Avatar>
        </div>
        <p className={styles["user-popup-name"]}>{user.name}</p>
        <p className={styles["user-popup-desc-text"]}>{title}</p>
        <p className={styles["user-popup-desc-text"]}>{user.email}</p>
      </div>
      <Button
        className={styles["user-popup-logout-button-container"]}
        icon={"logout"}
        onClick={() => {
          logout();
        }}
      >
        <div className={styles["user-popup-logout-button"]}>Logout</div>
      </Button>
      {tmiDarkMode && (
        <p className={styles["user-popup-dark-mode-container"]}>
          <span className={styles["user-popup-desc-text"]}>Dark Mode</span>
          <Switch
            className={styles["user-popup-dark-mode-switch"]}
            onChange={(event) => {
              setDarkTheme(event.target.value);
              Router.reload();
            }}
            defaultChecked={isDarkTheme}
          />
        </p>
      )}
    </Popup>
  ) : (
    <Popup className={styles["user-popup"]} show={show} anchor={anchor}>
      <Button
        icon="login"
        onClick={() => {
          login();
        }}
      >
        Login
      </Button>
    </Popup>
  );
};

function logout() {
  signOut({ callbackUrl: "/logged_out" });
}

function login() {
  signIn("okta", { callbackUrl: "/" });
}

const ProfileDropdown = () => {
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef<any>(null);
  const { data: session, status: sessionStatus } = useSession();
  const isSessionLoading = sessionStatus === "loading";

  const { data, loading: isUserInfoLoading } = useUserInfo();

  if (isSessionLoading || isUserInfoLoading) {
    return (
      <div>
        <Loader size="small" type="pulsing" />
      </div>
    );
  }

  const [smallAvatar, bigAvatar] = data?.me?.avatarImageURLs || [];
  const { title } = data?.me || { title: "" };
  return (
    <div onClick={() => setShow(!show)} ref={anchor}>
      <ProfileContent
        user={session?.user}
        anchor={anchor.current}
        show={show}
        avatarUrl={bigAvatar?.url}
        title={title}
      />

      <div className="k-hbox">
        <Avatar type="image" style={{ textAlign: "center", marginRight: 10 }}>
          {smallAvatar?.url ? (
            <Image
              height="50"
              width="50"
              src={smallAvatar.url}
              alt="My Profile"
            />
          ) : session ? (
            <Loader
              style={{ color: "#ffffff" }}
              size="small"
              type="infinite-spinner"
            />
          ) : (
            <FontAwesomeIcon icon="user" />
          )}
        </Avatar>
      </div>
    </div>
  );
};

export default ProfileDropdown;
