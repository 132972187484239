import { NavigationItem } from "@/types/navigation";
import { PRICING_LAUNCH_DARKLY_FLAGS } from "@/components/pricing/authorization";
import { PROMOTIONS_LAUNCH_DARKLY_FLAGS } from "@/components/promotions/authorization";

const navigation_items: NavigationItem[] = [
  { separator: true },
  {
    text: "Performance Reports",
    icon: "columns",
    flagName: "sidebarShowDashboards",
    children: [
      {
        text: "Hourly Report",
        route: "/dashboards/powerbi/hourly-report",
      },
      {
        text: "Product Diagnostics",
        route: "/dashboards/powerbi/product-diagnostics",
      },
      {
        text: "Product Coverage Report",
        route: "/dashboards/powerbi/product-coverage-report",
      },
      {
        text: "Inventory Tracker",
        route: "/dashboards/powerbi/inventory-tracker",
      },
    ],
  },
  { separator: true },
  {
    text: "Store Management",
    icon: "toolbox",
    flagName: "brandManagementPage",
    route: "/store-management",
  },
  { separator: true },
  {
    text: "Brand Management",
    icon: "toolbox",
    children: [
      {
        text: "TPID Correction Tool",
        route: "/tpid-correction-tool",
      },
      {
        text: "Brand Catalog",
        route: "/brand-catalog",
        flagName: "brandManagementPage",
      },
      {
        text: "Status Management",
        route: "/planning-status",
      },
    ],
  },
  { separator: true },
  {
    text: "Listing Management",
    icon: "map",
    flagName: "productCatalogBrandMapping",
    children: [
      {
        text: "List",
        route: "/listing-management/list",
      },
    ],
  },
  { separator: true },
  {
    text: "Marketing",
    icon: "toolbox",
    children: [
      {
        text: "Bid Management",
        route: "/marketing/bid-management",
        flagName: "marketingBidManagement",
      },
    ],
  },
  { separator: true },
  {
    text: "SKU Management",
    icon: "map",
    flagName: "skuManagementPage",
    children: [
      {
        text: "List",
        route: "/sku-management/list",
      },
      // {
      //   text: "Request",
      //   route: "/sku-management/request",
      // },
      // {
      //   text: "Update",
      //   route: "/sku-management/updateflow",
      // },
    ],
  },
  { separator: true },
  {
    text: "Affiliate Partners",
    icon: "user",
    flagName: "affiliatePartnerTools",
    oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
    children: [
      {
        text: "Publisher Sales Data",
        flagName: "affiliatePartnerDataIngestion",
        oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
        route: "/affiliate-partner-ingestion/publishers",
      },
      {
        text: "Influencer Sales Data",
        flagName: "affiliatePartnerDataIngestion",
        oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
        route: "/affiliate-partner-ingestion/influencers",
      },
    ],
  },
  { separator: true },
  {
    text: "Supply Chain Tools",
    icon: "shopping-cart",
    route: "/supply-chain/landing-page",
  },
  { separator: true },
  {
    text: "TPML",
    icon: "file-invoice-dollar",
    children: [
      {
        text: "TPML Dashboard",
        route: "/dashboards/powerbi/tpml",
      },
    ],
  },
  { separator: true },
  {
    text: "Samples",
    icon: "vials",
    flagName: "sidebarShowSamples",
    children: [
      {
        text: "Search Volume",
        route: "/samples/grid/seo-volume",
      },
      {
        text: "Search Rank",
        route: "/samples/grid/seo-rank",
      },
      {
        text: "Kendo Sample Grid",
        route: "/samples/grid/kendo",
      },
      {
        text: "Kendo Sample Form",
        route: "/samples/form/main",
      },
    ],
  },
  { separator: true, flagName: "sidebarShowScAutomationTools" },
  { separator: true },
  {
    text: "TRX FX Rates Upload",
    icon: "dollar-sign",
    flagName: "sidebarShowScAutomationToolsFxRates",
    route: "/transaction-aggregator/upload-rates",
  },
  { separator: true, flagName: "mAFeatureFlag" },
  { separator: true },
  {
    text: "Pricing",
    icon: "dollar-sign",
    children: [
      {
        text: "Syndication Center",
        route: "/pricing/syndication-center",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.syndicationCenterView,
      },
      {
        text: "Pricing Console",
        route: "/pricing/pricing-console",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.pricingConsoleView,
      },
      {
        text: "Rule Sets",
        route: "/pricing/rule-sets",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.ruleSetView,
      },
      {
        text: "Rule Set Executions",
        route: "/pricing/rule-set-executions",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.ruleSetExecutionView,
      },
      {
        text: "Price Calculation Targets",
        route: "/pricing/price-calculation-targets",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.calculationTargetView,
      },
      {
        text: "Segments",
        route: "/pricing/segments",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.segmentView,
      },
      {
        text: "Input Data Sources",
        route: "/pricing/input-data-sources",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.inputDataSourceView,
      },
      {
        text: "Holding Bin",
        route: "/pricing/holding-bin",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.holdingBinView,
      },
      {
        text: "Filters",
        route: "/pricing/filters",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.filterView,
      },
      {
        text: "Unit Econ",
        route: "/pricing/unit-econ",
        flagName: PRICING_LAUNCH_DARKLY_FLAGS.unitEconView,
      },
    ],
  },
  { separator: true },
  {
    text: "Promotions",
    icon: "dollar-sign",
    children: [
      {
        text: "Promotion Syndication",
        route: "/promotions/promotion-syndication",
        flagName: PROMOTIONS_LAUNCH_DARKLY_FLAGS.promotionSyndicationView,
      },
    ],
  },
  { separator: true },
  {
    text: "PowerBI",
    icon: "chart-line",
    children: [
      {
        text: "PowerBI Embed Admin",
        route: "/dashboards/powerbi/admin",
        flagName: "powerbiEmbeddingAdmin",
      },
    ],
  },
];

if (process.env.NODE_ENV !== "production") {
  navigation_items.push({
    text: "Engine Core",
    children: [
      {
        text: "Calculation Log Dialog",
        route: "/engine-core/examples/SampleCalculationLogDialogPage",
      },
      {
        text: "Comment Indicator",
        route: "/engine-core/examples/SampleCommentIndicatorPage",
      },
      {
        text: "Calc Target Filters",
        route: "/engine-core/examples/SampleCalcTargetFilterPage",
      },
      {
        text: "Manual Override Dialog",
        route: "/engine-core/examples/SampleManualOverrideDialogPage",
      },
      { text: "Rule Sets", route: "/engine-core/rule-sets" },
      { text: "Segments", route: "/engine-core/segments" },
      { text: "Input Data Sources", route: "/engine-core/input-data-sources" },
    ],
  });
}

export { navigation_items };
