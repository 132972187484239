import { FloatingActionButton } from "@progress/kendo-react-buttons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

type FloatingItemProps = {
  item: {
    text: string;
    url: string;
  };
};

const FloatingHelpButtonItem = ({ item }: FloatingItemProps) => {
  return (
    <span className="k-fab-item-text">
      <a href={item.url} target="_blank" rel="noreferrer">
        <span className="link-name">{item.text}</span>
      </a>
    </span>
  );
};

export const FloatingHelpButton = () => {
  const { pathname } = useRouter();
  const { showFabButton } = useFlags();
  try {
    if (showFabButton && pathname in showFabButton) {
      const { links } = showFabButton[pathname];
      if (links && links.length > 0) {
        return (
          <>
            <style>
              {`
                    .k-fab-popup {
                        box-shadow: none !important;
                    }
                    .k-fab-item-text {
                        font-size: 1rem;
                    }
                `}
            </style>
            <FloatingActionButton
              icon={"question"}
              text={"Help"}
              align={{
                horizontal: "start",
              }}
              themeColor={"info"}
              items={links}
              item={FloatingHelpButtonItem}
            />
          </>
        );
      }
    }
  } catch (exc) {
    // https://thrasio.atlassian.net/browse/RD-980
    // eslint-disable-next-line no-console
    console.log("Unable to generate Floating Help Button", exc);
  }

  return <></>;
};
