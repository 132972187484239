{
  /* https://thrasio.atlassian.net/browse/RD-983 */
}
{
  /* eslint-disable no-inline-styles/no-inline-styles */
}
import styles from "./DrawerContainer.module.css";
import { TMIViewModeContext } from "./TMIViewModeContext";
import { useContext } from "react";
import { Fragment, useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavigationItem } from "@/types/navigation";
import { isDefinedAndNonNull } from "@/components/util/object-util";

const DrawerContainer = (props: { items: NavigationItem[]; children: any }) => {
  const router = useRouter();
  const launchDarklyFlags = useFlags();
  const { data: session } = useSession();
  const { embedded } = useContext(TMIViewModeContext);

  const [expanded, setExpanded] = useState(!embedded);

  const CustomItem = (item: NavigationItem) => {
    // https://thrasio.atlassian.net/browse/RD-980
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { visible, flagName, oktaGroups, ...rest } = item;
    const arrowDir = item["data-expanded"] ? "chevron-down" : "chevron-right";

    return (
      <Fragment>
        {visible === false ? null : (
          <DrawerItem {...rest}>
            <span className={"k-item-text"}>
              {item.icon && (
                <FontAwesomeIcon
                  icon={item.icon as IconProp}
                  className={styles["drawer-icon"]}
                />
              )}
              {expanded && item.text}
            </span>
            {expanded &&
              item.children?.length > 0 &&
              item["data-expanded"] !== undefined && (
                <FontAwesomeIcon
                  icon={arrowDir}
                  className={styles["drawer-expanded-icon"]}
                />
              )}
          </DrawerItem>
        )}
      </Fragment>
    );
  };

  const handleClickExpand = () => {
    setExpanded((prevState) => !prevState);
    const newData = items.map((item) => {
      const { "data-expanded": currentExpanded, ...others } = item;
      return {
        "data-expanded":
          currentExpanded !== undefined ? false : currentExpanded,
        ...others,
      };
    });
    setItems(newData);
  };

  const [items, setItems] = useState<NavigationItem[]>(
    props.items.reduce(
      (
        accumulator: NavigationItem[],
        parent: NavigationItem,
        index: number
      ) => {
        const children = parent.children;
        parent.id = index;
        if (children) {
          parent["data-expanded"] = false;
          children.map((child: NavigationItem, childIndex: number) => {
            child.parentId = parent.id;
            child.selected = router.asPath === child.route;
            if (child.selected) {
              parent["data-expanded"] = true;
            }
            child.id = child.parentId * props.items.length + childIndex + 1;
            return child;
          });
          return accumulator.concat(parent, children);
        } else {
          parent.selected = router.asPath === parent.route;
          return accumulator.concat(parent);
        }
      },
      []
    )
  );

  const onSelect = async (ev: DrawerSelectEvent) => {
    const currentItem: NavigationItem = ev.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];

    const newData = items.map((item) => {
      const {
        // https://thrasio.atlassian.net/browse/RD-980
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selected,
        "data-expanded": currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id && !currentItem.selectable;
      return {
        selected: isCurrentItem,
        "data-expanded":
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    setItems(newData);
    if (!expanded) {
      handleClickExpand();
    }
    if (!!currentItem.route) {
      await router.push({ pathname: currentItem.route });
    }
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;

    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      const itemLDFlagOn =
        item.flagName && launchDarklyFlags[item.flagName] === true;
      const isPartOfOktaGroup = item.oktaGroups
        ? session.user.groups.filter((oktaGroup) =>
            item.oktaGroups.includes(oktaGroup)
          ).length > 0
        : false;

      // Toggle visibility if an LD flag exists and is enabled, or if the parent is expanded,
      // or if the user is part of the listed okta groups
      const visible =
        itemLDFlagOn || !item.flagName
          ? parent["data-expanded"]
          : false || isPartOfOktaGroup;
      return {
        ...others,
        visible,
      };
    } else if (item.flagName) {
      const itemLDFlagOn = launchDarklyFlags[item.flagName] === true;
      return {
        ...others,
        visible: itemLDFlagOn,
      };
    } else if (item.children) {
      // visible if one of the flags is true or there are pages with no flags
      return {
        ...others,
        visible: item.children.some((c) =>
          isDefinedAndNonNull(c.flagName)
            ? launchDarklyFlags[c.flagName] === true
            : true
        ),
      };
    }
    return item;
  });

  return (
    <>
      <Drawer
        expanded={expanded}
        mode="push"
        width={250}
        items={data}
        item={CustomItem}
        onSelect={onSelect}
        mini={true}
        style={{
          position: "relative",
        }}
      >
        <DrawerContent className={styles["drawer-content"]}>
          {props.children}
        </DrawerContent>
        {!embedded && (
          <Button
            style={{
              display: "flex",
              justifyContent: "left",
              position: "absolute",
              bottom: -50,
            }}
            fillMode="flat"
            onClick={handleClickExpand}
          >
            {" "}
            <FontAwesomeIcon
              icon={
                expanded
                  ? ("fa-solid fa-minimize" as IconProp)
                  : ("fa-solid fa-maximize" as IconProp)
              }
              className={styles["drawer-icon"]}
            />{" "}
            {expanded && "Collapse"}
          </Button>
        )}
      </Drawer>
    </>
  );
};

export default DrawerContainer;
