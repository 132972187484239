import ProfileDropdown from "./ProfileDropdown";
import DrawerContainer from "./DrawerContainer";
import Auth from "./Auth";
import { TMIViewModeContext } from "./TMIViewModeContext";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import getConfig from "next/config";
import Image from "next/image";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { useSession } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { navigation_items } from "@/components/navigation";
import CourierInbox from "@/platform/CourierInbox";
import { FloatingHelpButton } from "@/components/floating-help-button/FloatingHelpButton";
import { DarkMode } from "@/platform/darkMode";
import { NotificationProvider } from "@/components/common/notifications/NotificationContext";
import Notifications from "@/components/common/notifications/Notifications";

function PageContainer({ children }) {
  const { query } = useRouter();
  const { data: session } = useSession();
  let ldContext;
  if (session?.user) {
    const { name, email, groups, id } = session?.user;
    ldContext = { kind: "user", key: id, name, email, groups };
  } else {
    ldContext = { kind: "user", anonymous: true };
  }

  const { publicRuntimeConfig } = getConfig();
  const embedded = query.embedded === "true";

  return (
    <Auth>
      <LDProvider
        clientSideID={publicRuntimeConfig.launchDarklyClientId}
        context={ldContext}
      >
        <TMIViewModeContext.Provider value={{ embedded }}>
          <DarkMode />
          <Head>
            <title>Thrasio Tools</title>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="anonymous"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans&display=swap"
              rel="stylesheet"
            />
          </Head>
          <AppBar id="app">
            <AppBarSection>
              <Link href="/">
                {/* https://thrasio.atlassian.net/browse/RD-983 */}
                {/* eslint-disable no-inline-styles/no-inline-styles */}
                <div
                  style={{
                    position: "relative",
                    width: "220px",
                    height: "30px",
                  }}
                >
                  {/* https://thrasio.atlassian.net/browse/RD-983 */}
                  {/* eslint-enable no-inline-styles/no-inline-styles */}
                  <Image
                    alt="Thrasio Logo"
                    priority={false}
                    src="/ThrasioLogo.svg"
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
              </Link>
            </AppBarSection>

            <AppBarSpacer />

            <AppBarSection>
              <CourierInbox />
            </AppBarSection>

            {/* https://thrasio.atlassian.net/browse/RD-983 */}
            {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
            <AppBarSpacer style={{ width: 4 }} />

            <AppBarSection>
              <ProfileDropdown />
            </AppBarSection>
          </AppBar>

          <NotificationProvider>
            <DrawerContainer items={navigation_items}>
              {children}
            </DrawerContainer>
            <Notifications />
          </NotificationProvider>
          <FloatingHelpButton />
        </TMIViewModeContext.Provider>
      </LDProvider>
    </Auth>
  );
}

export default PageContainer;
