import { useFlags } from "launchdarkly-react-client-sdk";

// useFlags is a hook and needs to be called within a functional component
export const GetLaunchDarklyFlagAuthorization = (flag: string) => {
  const flags = useFlags();

  return flag in flags && flags[flag] === true;
};

export const GetLaunchDarklyFlagAuthorizations = (
  requested_flags: string[]
) => {
  const all_flags = useFlags();

  return requested_flags.reduce((authFlags: Object, flag: string) => {
    if (!Object.keys(authFlags).includes(flag)) {
      authFlags[flag] = all_flags[flag];
    }

    return authFlags;
  }, {});
};
