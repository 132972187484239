import { GetLaunchDarklyFlagAuthorization } from "@/utils/authorization-utils";

const LD_PRICING_GENERAL_USERS = "pricingGeneralUsers";
const LD_PRICING_SUPER_USERS = "pricingSuperUsers";

export const PRICING_LAUNCH_DARKLY_FLAGS = {
  /*
  PLEASE NAME FLAGS STARTING WITH THE RELATED ENTITY - AND KEEP ALL FLAGS RELATED TO THE SAME ENTITY TOGETHER
   */
  adjustmentEdit: LD_PRICING_SUPER_USERS,

  bulkUploadSubmit: LD_PRICING_SUPER_USERS,

  calculationTargetView: LD_PRICING_GENERAL_USERS,

  enablePricingProductionRuleSetEdit: "enablePricingProductionRuleSetEdit",
  enablePricingIncrementalPriceChangeGeneration:
    "enablePricingIncrementalPriceChangeGeneration",

  filterEdit: LD_PRICING_SUPER_USERS,
  filterView: LD_PRICING_GENERAL_USERS,

  guardrailEdit: LD_PRICING_SUPER_USERS,

  holdingBinComment: LD_PRICING_SUPER_USERS,
  holdingBinOverride: LD_PRICING_SUPER_USERS,
  holdingBinRelease: LD_PRICING_SUPER_USERS,
  holdingBinTurnOff: LD_PRICING_SUPER_USERS,
  holdingBinView: LD_PRICING_GENERAL_USERS,

  incrementalSyndicationTrigger: LD_PRICING_SUPER_USERS,

  inputDataSourceView: LD_PRICING_GENERAL_USERS,
  inputDataSourceEdit: LD_PRICING_SUPER_USERS,

  pricingConsoleView: LD_PRICING_GENERAL_USERS,

  priceOverrideEdit: LD_PRICING_SUPER_USERS,
  priceOverrideComment: LD_PRICING_GENERAL_USERS,

  ruleEdit: LD_PRICING_SUPER_USERS,

  ruleSetEdit: LD_PRICING_SUPER_USERS,
  ruleSetPromote: LD_PRICING_SUPER_USERS,
  ruleSetView: LD_PRICING_GENERAL_USERS,

  ruleSetExecutionView: LD_PRICING_GENERAL_USERS,

  segmentEdit: LD_PRICING_SUPER_USERS,
  segmentView: LD_PRICING_GENERAL_USERS,

  syndicationCenterView: LD_PRICING_GENERAL_USERS,

  unitEconView: LD_PRICING_GENERAL_USERS,
  unitEconEdit: LD_PRICING_SUPER_USERS,
};

export const getPricingRuleSetEditAuthorization = (ruleSetType, flag) => {
  const userCanEdit = GetLaunchDarklyFlagAuthorization(flag);
  const productionRuleEditFlagEnabled = GetLaunchDarklyFlagAuthorization(
    PRICING_LAUNCH_DARKLY_FLAGS.enablePricingProductionRuleSetEdit
  );
  const canEdit =
    (ruleSetType !== "PRODUCTION" || productionRuleEditFlagEnabled) &&
    userCanEdit;

  return canEdit;
};
